import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';

const HSRGuidesFHPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-dps-rankings'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Forgotten Hall - Memory of Chaos</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_fh.png"
            alt="Forgotten Hall"
          />
        </div>
        <div className="page-details">
          <h1>Forgotten Hall - Memory of Chaos</h1>
          <h2>
            A guide for all the floors of Forgotten Hall Memory of Chaos mode in
            Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Memory of Chaos" />
        <p>
          This guide is archived. Check our{' '}
          <Link to="/star-rail/memory-of-chaos">Memory of Chaos Analytics</Link>{' '}
          guide instead.
        </p>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesFHPage;

export const Head: React.FC = () => (
  <Seo
    title="Memory of Chaos | Honkai: Star Rail | Prydwen Institute"
    description="A guide for the Forgotten Hall Memory of Chaos mode in Honkai: Star Rail."
  />
);
